var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-dialog',{attrs:{"max-width":'1000px',"persistent":"","transition":"dialog-top-transition"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.showDetail),callback:function ($$v) {_vm.showDetail=$$v},expression:"showDetail"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))]),_c('v-spacer'),(_vm.progress)?_c('v-layout',{attrs:{"row":"","justify-center":"","align-center":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":30,"width":7,"color":"purple"}})],1):_vm._e(),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"blue darken-1","disabled":_vm.save_disable,"small":""},on:{"click":_vm.save}},[_vm._v(" Enregistrer ")]),_c('v-btn',{attrs:{"color":"blue darken-1","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_vm._v(" Annuler ")])],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[(
                  _vm.mvm_type == 19 &&
                  !_vm.allproduct &&
                  _vm.editedItem.id < 0 &&
                  !_vm.client_unique &&
                  !_vm.isloading
                )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-autocomplete',{attrs:{"item-value":'tier_id',"items":_vm.products,"filter":_vm.customFilter,"label":"Filtre par client","readonly":!_vm.modify || _vm.form_list.length > 0,"clearable":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s((item.tier_code ? item.tier_code + "-" : "") + item.tier_name)+" ")]}},{key:"item",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s((item.tier_code ? item.tier_code + "-" : "") + item.tier_name)+" ")]}}],null,false,3142091804),model:{value:(_vm.client_id),callback:function ($$v) {_vm.client_id=$$v},expression:"client_id"}})],1):_vm._e(),(
                  ((_vm.mvm_type == 1 && !_vm.isdossier) || _vm.mvm_type == 19) &&
                  !_vm.allproduct &&
                  _vm.editedItem.id < 0 &&
                  !_vm.cmd_unique &&
                  !_vm.isloading
                )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-autocomplete',{attrs:{"item-value":'cmd_id',"items":_vm.products_cmd,"filter":_vm.customFilter,"label":"Filtre par commande","readonly":!_vm.modify || _vm.form_list.length > 0,"clearable":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.nocmd + " du " + _vm.datefr(item.date_cmd) + " - " + (_vm.mvm_type == 19 ? "Client " : "Fournisseur ") + item.tier_name)+" ")]}},{key:"item",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.nocmd + " du " + _vm.datefr(item.date_cmd) + " - " + (_vm.mvm_type == 19 ? "Client " : "Fournisseur ") + item.tier_name)+" ")]}}],null,false,4112662588),model:{value:(_vm.cmd_id),callback:function ($$v) {_vm.cmd_id=$$v},expression:"cmd_id"}})],1):_vm._e(),(
                  (_vm.mvm_type == 1 ||
                    _vm.mvm_type == 17 ||
                    _vm.mvm_type == 19 ||
                    _vm.isdossier) &&
                  !_vm.allproduct
                )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"10"}},[_c('v-autocomplete',{attrs:{"item-value":_vm.isdossier ? 'cmd_detail_id' : 'id',"items":_vm.mvm_type == 19 || (_vm.mvm_type == 1 && !_vm.isdossier)
                      ? _vm.products_list
                      : _vm.products,"filter":_vm.customFilter,"label":"Produit","readonly":!_vm.modify || _vm.form_list.length > 0,"rules":[function (v) { return !!v || 'Produit obligatoire'; }],"dense":"","loading":_vm.isloading},on:{"change":_vm.produit_change},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s((item.product_code ? item.product_code + "-" : "") + item.label)+" ")]}},{key:"item",fn:function(ref){
                      var item = ref.item;
return [_c('v-list',{attrs:{"max-width":"700"}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(
                            (item.product_code
                              ? item.product_code + '-'
                              : '') + item.label
                          )}}),(item.ref)?_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s('Ref. : ' + item.ref)}}):_vm._e(),(_vm.mvm_type == 19)?_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(
                            'Client : ' +
                            item.tier_code +
                            '-' +
                            item.tier_name
                          )}}):_vm._e(),(!_vm.isdossier)?_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(
                            'Commande N° : ' +
                            item.nocmd +
                            ' du ' +
                            _vm.datefr(item.date_cmd)
                          )}}):_vm._e(),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(
                            'Qte ' +
                            (_vm.isdossier ? 'Arrivage ' : 'Commandée ') +
                            (item.qte ? _vm.numberWithSpace(item.qte) : 0) +
                            ' ' +
                            (item.unit ? item.unit : '')
                          )}}),_c('v-list-item-subtitle',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('span',[_vm._v(" "+_vm._s("Qte Receptionnée : " + _vm.numberWithSpace( _vm.isdossier ? item.qte_rcpt : _vm.iscmd ? item.qte_liv : item.qte_rcpt ) + " " + (item.unit ? item.unit : ""))+" ")])]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-progress-linear',{attrs:{"value":_vm.isdossier || _vm.mvm_type == 19
                                    ? item.prc_rcpt
                                    : item.prc_liv,"color":"light-green darken-2","height":"25","rounded":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v(" "+_vm._s((_vm.isdossier || _vm.mvm_type == 19 ? item.prc_rcpt : item.prc_liv) + "% ")+" ")]},proxy:true}],null,true)})],1)],1)],1),(_vm.mvm_type == 1 || _vm.mvm_type == 19)?_c('v-list-item-subtitle',{class:!item.stock_cmpt || item.stock_cmpt == 0
                              ? 'red'
                              : 'green',domProps:{"innerHTML":_vm._s(
                            'Stock : ' +
                            (item.stock_cmpt
                              ? _vm.numberWithSpace(item.stock_cmpt)
                              : 0) +
                            ' ' +
                            (item.unit ? item.unit : '')
                          )}}):_vm._e()],1)],1)]}}],null,false,186657837),model:{value:(_vm.editedItem.cmd_det_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "cmd_det_id", $$v)},expression:"editedItem.cmd_det_id"}})],1):_c('v-col',{attrs:{"cols":"12","sm":"6","md":"10"}},[_c('v-autocomplete',{attrs:{"item-value":'produit_id',"items":_vm.products,"filter":_vm.customFilter,"label":"Produit","readonly":!_vm.modify || _vm.form_list.length > 0,"rules":[function (v) { return !!v || 'Produit obligatoire'; }],"dense":""},on:{"change":_vm.produit_change},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s((item.product_code ? item.product_code + "-" : "") + item.product_name)+" ")]}},{key:"item",fn:function(ref){
                          var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(
                          (item.product_code ? item.product_code + '-' : '') +
                          item.product_name
                        )}}),(item.ref)?_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s('Ref. : ' + item.ref)}}):_vm._e()],1)]}}]),model:{value:(_vm.editedItem.produit_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "produit_id", $$v)},expression:"editedItem.produit_id"}})],1),(
                  _vm.editedItem.produit_id &&
                  !(_vm.mvm_type == 19 && _vm.articles.length == 1)
                )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-autocomplete',{attrs:{"item-value":_vm.article_field,"items":_vm.articles,"filter":_vm.customFilter,"label":"Article","readonly":!_vm.modify || _vm.form_list.length > 0,"rules":[function (v) { return !!v || 'Article obligatoire'; }],"dense":"","loading":_vm.data_load},on:{"change":_vm.article_change},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s((item.ref_ext ? "(" + item.ref_ext + ")" + "-" : "") + (_vm.mvm_type == 18 ? item.code_article : item.code) + "-" + item.label)+" ")]}},{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(
                          (_vm.mvm_type == 18 ? item.code_article : item.code) +
                          '-' +
                          item.label
                        )}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(
                          _vm.importdetails.length > 0
                            ? 'Arrivage : ' +
                              (item.qte ? item.qte : 0) +
                              ' ' +
                              (item.unit ? item.unit : '')
                            : ''
                        )}}),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.ref_ext ? "Ref. Ext. " + item.ref_ext : "")+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.tier_name ? "Fournisseur : " + item.tier_name : "")+" ")]),_c('v-list-item-subtitle',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('span',[_vm._v(" "+_vm._s("Qte Receptionnée : " + _vm.numberWithSpace(item.qte_rcpt) + " " + (item.unit ? item.unit : ""))+" ")])])],1)],1)],1)]}}],null,false,3011375164),model:{value:(_vm.editedItem.article_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "article_id", $$v)},expression:"editedItem.article_id"}})],1):_vm._e(),(
                  _vm.editedItem.produit_id &&
                  (!_vm.editedItem.pu ||
                    _vm.$store.state.auth.includes('01027') ||
                    _vm.$store.state.isadmin)
                )?_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-text-field',{ref:"pu",staticClass:"inputPrice",attrs:{"autocomplete":"off","type":"number","readonly":_vm.iscmd ||
                    _vm.isdossier ||
                    !(
                      _vm.$store.state.auth.includes('02027') ||
                      _vm.$store.state.isadmin
                    ) ||
                    !_vm.modify,"label":_vm.mvm_type == 18 || _vm.mvm_type == 19
                      ? 'Prix de revient'
                      : 'P.U.(DZD)',"dense":"","hide-spin-buttons":"","rules":[
                    function (v) { return !!v ||
                      (_vm.mvm_type == 18 || _vm.mvm_type == 19
                        ? 'Prix de revient'
                        : 'Prix unitaire') + ' obligatoire'; } ]},on:{"focus":function($event){return $event.target.select()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$refs.qte.focus()}},model:{value:(_vm.editedItem.pu),callback:function ($$v) {_vm.$set(_vm.editedItem, "pu", _vm._n($$v))},expression:"editedItem.pu"}})],1):_vm._e(),(
                  (_vm.$store.state.auth.includes('02027') ||
                    _vm.$store.state.isadmin) &&
                  _vm.remise
                )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-text-field',{staticClass:"inputPrice",attrs:{"autocomplete":"off","type":"number","readonly":!(
                      _vm.$store.state.auth.includes('02027') ||
                      _vm.$store.state.isadmin
                    ) || !_vm.modify,"label":"Remise(%)","rules":[
                    function (v) { return !v || (v > 0 && v <= 100) || 'Valeur entre 1 et 100'; } ],"dense":"","hide-spin-buttons":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.remise),callback:function ($$v) {_vm.$set(_vm.editedItem, "remise", _vm._n($$v))},expression:"editedItem.remise"}})],1):_vm._e(),(_vm.editedItem.service == '0')?_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-autocomplete',{ref:"mvmto",attrs:{"item-value":"id","item-text":"label","items":_vm.depots,"readonly":!_vm.modify || _vm.form_list.length > 0,"label":"Depot de Destination","rules":[
                    function (v) { return !!v || 'Depot obligatoire'; },
                    function (v) { return _vm.ligne_exist(
                        _vm.iscmd && !_vm.linked && !_vm.isdossier
                          ? _vm.editedItem.cmd_det_id
                          : _vm.editedItem.article_id,
                        v
                      ) == false || 'Ligne existe!'; } ],"dense":""},on:{"change":_vm.depot_change},model:{value:(_vm.mvm_to),callback:function ($$v) {_vm.mvm_to=$$v},expression:"mvm_to"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({ref:"qte",staticClass:"inputPrice",attrs:{"autocomplete":"off","type":"number","dense":"","label":'Quantité ',"rules":[
                        function (v) { return !!v || 'Quantité obligatoire'; },
                        function (v) { return parseFloat(v) <= parseFloat(_vm.qte_max) ||
                          (!_vm.iscmd && !_vm.isdossier) ||
                          !_vm.modify ||
                          'Maximum autorisé ' + _vm.qte_max; } ],"hint":_vm.iscmd || _vm.isdossier
                          ? 'Reste à Receptionner ' +
                            (_vm.qte_rst - (_vm.editedItem.qte ? _vm.editedItem.qte : 0))
                          : '',"readonly":_vm.editedItem.bylist == '1' ||
                        (_vm.editedItem.bylist == '0' && _vm.depot.NbRack > 0) ||
                        !_vm.modify,"hide-spin-buttons":"","disabled":(!_vm.mvm_to && _vm.editedItem.service == '0') || _vm.save_disable},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save.apply(null, arguments)},"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.qte),callback:function ($$v) {_vm.$set(_vm.editedItem, "qte", _vm._n($$v))},expression:"editedItem.qte"}},'v-text-field',attrs,false),on))]}}])},[(_vm.iscmd || _vm.isdossier)?_c('div',[_c('li',[_vm._v(" Quantité "+_vm._s(_vm.numberWithSpace(_vm.qte))+" ")]),_c('li',[_vm._v(" "+_vm._s("Quantité Receptionnée " + _vm.numberWithSpace( _vm.editedItem.fk_unit == _vm.editedItem.fk_unit_product ? _vm.qte_liv.toFixed(_vm.precision) : _vm.qte_liv ))+" ")])]):_vm._e()])],1),_c('v-col',{attrs:{"cols":"12","sm":"1","md":"2"}},[_c('v-select',{ref:"unite",attrs:{"items":_vm.units_list,"item-text":"name","item-value":"id","label":"Unité de Mesure","readonly":_vm.iscmd ||
                    _vm.isdossier ||
                    _vm.mvm_type == 18 ||
                    _vm.mvm_type == 19 ||
                    !_vm.modify,"rules":[function (v) { return !!v || 'Unité de Mesure obligatoire'; }],"dense":""},on:{"change":_vm.unit_change},model:{value:(_vm.editedItem.fk_unit),callback:function ($$v) {_vm.$set(_vm.editedItem, "fk_unit", $$v)},expression:"editedItem.fk_unit"}})],1),(
                  _vm.editedItem.produit_id &&
                  (!_vm.editedItem.pu ||
                    _vm.$store.state.auth.includes('01027') ||
                    _vm.$store.state.isadmin) &&
                  _vm.mvm.country_id == 13
                )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"1"}},[_c('v-text-field',{ref:"tva",staticClass:"inputPrice",attrs:{"autocomplete":"off","type":"number","dense":"","label":"TVA","readonly":_vm.iscmd || _vm.isdossier || !_vm.modify,"rules":[
                    function (v) { return !v || parseFloat(v) > 0 || 'Valeur incorrecte'; } ],"hide-spin-buttons":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.tva_tx),callback:function ($$v) {_vm.$set(_vm.editedItem, "tva_tx", _vm._n($$v))},expression:"editedItem.tva_tx"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"1","md":"12"}},[_c('v-text-field',{ref:"comment",attrs:{"autocomplete":"off","dense":"","label":"Commentaire"},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.comment),callback:function ($$v) {_vm.$set(_vm.editedItem, "comment", $$v)},expression:"editedItem.comment"}})],1),(_vm.mvm_to && _vm.depot.NbRack > 0 && _vm.editedItem.bylist == '1')?_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-autocomplete',{ref:"boxs",attrs:{"item-value":"id","items":_vm.boxs_list,"readonly":!_vm.modify,"filter":_vm.customFilter,"label":(_vm.depot.box ? _vm.depot.box : 'Box') + ' Par defaut',"clearable":"","dense":""},on:{"change":_vm.box_change},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.box)+" ")]}},{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.box)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.depot.rack + " " + item.rack)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s("Stock " + item.stock_cmpt.toFixed(2))+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s("Nb Article " + item.nbarticle)+" ")])],1)]}}],null,false,465655940),model:{value:(_vm.editedItem.box_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "box_id", $$v)},expression:"editedItem.box_id"}})],1):_vm._e(),(
                  (_vm.editedItem.bylist == '1' ||
                    (_vm.editedItem.bylist == '0' && _vm.depot.NbRack > 0)) &&
                  _vm.editedItem.article_id > 0 &&
                  _vm.mvm_to > 0
                )?_c('v-col',{attrs:{"cols":"12","sm":"4","md":"12 "}},[_c('listitems',{key:_vm.frml,attrs:{"list":_vm.form_list,"headers":_vm.editedItem.bylist == '1' ? _vm.colisheaders : _vm.boxheaders,"title":_vm.list_title,"master":true,"add":true,"del":true,"Update":true,"showstd":true,"Total":true,"print":true,"exp_excel":true,"imp_excel":_vm.editedItem.bylist == '1',"multiple":false,"laoding":_vm.listload,"ipg":5},on:{"excel_import":_vm.excel_import,"delete":_vm.del_item,"add":_vm.add_item,"selected_rows":_vm.selected_right_change}})],1):_vm._e()],1)],1)],1)],1)],1)],1),_c('confirmdialog',{ref:"confirm"}),_c('v-snackbar',{attrs:{"timeout":_vm.snackbar_timeout,"top":"","color":_vm.snackbar_color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }